var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.event && _vm.event.lookup_ref)?_c('div',{staticClass:"page-header"},[_c('div',{staticClass:"event-detail"},[_c('router-link',{staticClass:"event-name",attrs:{"to":{
        name: 'event',
        params: {
          event_lookup_ref: _vm.event.lookup_ref
            ? _vm.event.lookup_ref
            : this.$route.params.event_lookup_ref,
        },
        query: _vm.$defaultQuery(),
      }}},[_vm._v(" "+_vm._s(_vm.event.name)+" ")]),(_vm.event.place)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.event.place.name))]),(_vm.event.place.municipality)?_c('span',{staticClass:"municipality"},[_vm._v(" - "+_vm._s(_vm.event.place.municipality)+" ")]):_vm._e()]):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }