




























































































import "reflect-metadata"
import { Component, Prop } from "vue-property-decorator"
import Vue from "vue"
import CalendarDayList from "@/components/calendar/CalendarDayList.vue"

@Component({
  components: { CalendarDayList },
})
export default class EventMonthlyCalendar extends Vue {
  selectedDay: any = {}
  dayDetailSessions: Dictionary<Array<any>> = {}

  @Prop({ default: {} }) event!: any

  get weeks(): any {
    if (this.event.id) {
      return this.event.calendar.weeks
    }

    return []
  }

  changeMonth(year: number, month: number) {
    const params: any = { year, month, ...this.$defaultQuery() }
    this.$router.replace({ query: params })
    this.$emit("changeMonth", params)
  }

  displayDayDetail(week_index: number, day_obj: any) {
    if (!day_obj.has_sessions) {
      return
    }

    if (this.dayDetailSessions[week_index]) {
      this.dayDetailSessions = {}
      this.selectedDay = {}
      return
    }

    this.dayDetailSessions = {}
    this.selectedDay = {}

    this.dayDetailSessions[week_index] = day_obj.sessions
    this.selectedDay = day_obj
  }
}
