











import "reflect-metadata"
import { Component, Prop } from "vue-property-decorator"
import Vue from "vue"
import SessionCardCalendarList from "@/components/SessionCardCalendarList.vue"

@Component({
  components: { SessionCardCalendarList },
})
export default class CalendarDayList extends Vue {
  @Prop() event!: any
  @Prop() sessions!: any
}
