<template>
  <div class="modal" :class="{ 'is-active': active }" scroll="keep">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="modal-card-body">
        <p class="mt-2 is-size-2 has-text-weight-light" v-translate>Pedido en curso</p>
        <div class="my-5 is-size-5" v-translate>
          Tienes un pedido en curso para este evento ¿Deseas continuar donde lo dejaste?
        </div>
        <div class="mt-4">
          <b-button class="is-fullwidth has-text-grey" @click="abandon" v-translate>
            No, quiero realizar un nuevo pedido
          </b-button>
          <b-button
            class="mt-2 is-fullwidth"
            type="is-info"
            @click="goToOrder"
            v-translate
          >
            Sí, deseo continuar con el pedido
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import { mapState, mapActions } from "vuex"
export default {
  name: "OnGoingOrderDialog",
  props: ["eventRef"],
  data() {
    return {
      active: false,
      lastOrderData: {},
    }
  },
  computed: {
    ...mapState({
      orderEvents: (state) => state.order.events,
    }),
  },
  methods: {
    ...mapActions({
      getEventOrder: "order/getEventOrder",
      resetEvent: "order/resetEvent",
      abandonOrder: "order/abandon",
    }),
    getOrder(ref) {
      this.$api
        .getOrder(ref)
        .then((response) => {
          this.lastOrderData = response
        })
        .catch(() => {
          this.resetEvent(this.eventRef)
        })
    },
    isLastOrderActive() {
      return _.includes(["draft", "pending"], this.lastOrderData.status)
    },
    abandon() {
      this.abandonOrder(this.eventRef).finally(() => (this.active = false))
    },
    goToOrder() {
      this.$router.push({
        name: "order-confirm",
        params: { lookup_ref: this.lastOrderData.lookup_ref },
        query: this.$defaultQuery(),
      })
    },
  },
  watch: {
    orderEvents: {
      handler(value) {
        let orderRef = value[this.eventRef].order_lookup_ref
        if (orderRef) {
          this.getOrder(orderRef)
        } else {
          this.active = false
        }
      },
      deep: true,
    },
    lastOrderData: {
      handler() {
        if (!_.size(this.lastOrderData)) return
        if (this.isLastOrderActive()) {
          this.active = true
        } else {
          this.resetEvent(this.eventRef)
        }
      },
      deep: true,
    },
  },
}
</script>
