<template>
  <section>
    <div class="modal" :class="{ 'is-active': true }" scroll="keep">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="modal-card-body">
          <div class="continer-title mt-2">
            <h2 class="title has-text-weight-light">
              <translate>Se ha terminado el tiempo para realizar el pedido.</translate>
            </h2>
          </div>
          <div class="button-container">
            <b-button @click="reloadPage()">
              <translate>Volver a la cola</translate>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    reloadPage() {
      window.location.reload()
    },
  },
}
</script>

<style lang="sass" scoped>
.button-container
    width: 100%
    text-align: center
    margin-top: 1rem
</style>
