



































import "reflect-metadata"
import { Component } from "vue-property-decorator"
import Vue from "vue"
import filters from "@/filters"

import OnGoingOrderDialog from "@/components/dialogs/OnGoingOrderDialog.vue"
import EventDetail from "@/components/EventDetail.vue"
import SessionCard from "@/components/SessionCard.vue"
import EventCalendar from "@/components/event_display_mode/EventCalendar.vue"
import EventGrouped from "@/components/event_display_mode/EventGrouped.vue"
import EventUngrouped from "@/components/event_display_mode/EventUngrouped.vue"
import TokenTimer from "@/components/TokenTimer.vue"
import TokenExpiredDialog from "@/components/TokenExpiredDialog.vue"

@Component({
  filters: filters,
  components: {
    OnGoingOrderDialog,
    EventDetail,
    SessionCard,
    EventCalendar,
    EventGrouped,
    EventUngrouped,
    TokenTimer,
    TokenExpiredDialog,
  },
})
export default class Event extends Vue {
  cart = []
  eventSimple: any = {}
  // It may empty if mode is calendar
  event: any = {}
  tokenExpired = false

  mounted() {
    this.getEventSimple()
  }

  get mode() {
    return this.eventSimple.session_display_mode || ""
  }

  get isLoading() {
    return !this.eventSimple || !this.eventSimple.lookup_ref
  }

  get lookup_ref() {
    return this.$route.params.event_lookup_ref
  }

  getEventSimple() {
    this.$api.getEventSimple(this.lookup_ref).then(
      (response: any) => {
        this.eventSimple = response
        if (response && this.eventSimple.session_display_mode !== "calendar") {
          this.getEvent()
        }
      },
      () => {}
    )
  }

  getEvent() {
    this.$api.getEvent(this.lookup_ref).then(
      (response: any) => {
        this.event = response
        this.notifyGTM(this.event)
      },
      () => {}
    )
  }

  notifyGTM(event: any) {
    this.$store.dispatch("gtm2404/viewCart", { event })
  }

  get ffTokenTimerEnabled() {
    return this.$env && this.$env.ff_token_timer === true
  }
}
