





























































import "reflect-metadata"
import { Component, Prop } from "vue-property-decorator"
import Vue from "vue"
import filters from "@/filters"

@Component({
  filters: filters,
})
export default class SessionCardCalendarList extends Vue {
  @Prop() session!: any
  @Prop() event!: any

  sessionClick() {
    if (!this.clickable) return
    if (this.session.lookup_ref == this.$route.params.session_lookup_ref) return
    this.$store.dispatch("gtm/step1", { event: this.event, session: this.session })
    this.$store.dispatch("shoppingCart/update", ["resetCart"])
    this.$router.push({
      name: "session",
      params: {
        event_lookup_ref: this.$route.params.event_lookup_ref,
        session_lookup_ref: this.session.lookup_ref,
      },
      query: this.$defaultQuery(),
    })
  }

  get available() {
    return this.session.min_total_price != null
  }
  get purchasable() {
    return this.session.purchasable_seats_count > 0
  }
  get clickable() {
    return this.available && this.purchasable
  }
  get showTotalAvailableSeats() {
    return this.session.active_seats_count != null
  }
  get checkoutType() {
    if (this.event.sessions.length == 1) {
      return "ch_corto"
    }
    return "ch_largo"
  }
}
