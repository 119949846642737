




























import { Component, Prop, Watch } from "vue-property-decorator"
import Vue from "vue"
import filters from "@/filters"

@Component({
  filters: filters,
})
export default class ZonesDropdown extends Vue {
  @Prop() value!: string
  @Prop() zones!: Array<Dictionary<any>>

  currentValue: string | null = null

  mounted() {
    this.currentValue = this.value
  }

  goToZone(zone: Dictionary<any>) {
    if (zone.id == this.$route.params.zone_id) return
    this.currentValue = zone.name
    this.$router.push({
      name: "concessions",
      params: {
        event_lookup_ref: this.$route.params.event_lookup_ref,
        session_lookup_ref: this.$route.params.session_lookup_ref,
        zone_id: zone.id,
      },
      query: this.$defaultQuery(),
    })
  }

  get enabled() {
    return this.zones.length > 1
  }

  @Watch("value")
  onValueChange() {
    this.currentValue = this.value
  }
}
