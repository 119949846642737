

































import "reflect-metadata"
import { Component, Prop } from "vue-property-decorator"
import Vue from "vue"
import SessionCard from "@/components/SessionCard.vue"
import filters from "@/filters"
import _ from "lodash"

@Component({
  filters: filters,
  components: { SessionCard },
})
export default class EventGrouped extends Vue {
  @Prop() event!: any

  get sessionGrouped(): any {
    return _.groupBy(this.event.sessions, "start_date")
  }
}
