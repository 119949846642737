





























































import "reflect-metadata"
import { Component, Prop } from "vue-property-decorator"
import Vue from "vue"
import filters from "@/filters"

import OnGoingOrderDialog from "@/components/dialogs/OnGoingOrderDialog.vue"
import EventDetail from "@/components/EventDetail.vue"
import SessionCardCalendarList from "@/components/SessionCardCalendarList.vue"
import EventMonthlyCalendar from "@/components/calendar/EventMonthlyCalendar.vue"

@Component({
  filters: filters,
  components: {
    OnGoingOrderDialog,
    EventDetail,
    EventMonthlyCalendar,
    SessionCardCalendarList,
  },
})
export default class EventCalendar extends Vue {
  cart = []
  calendarEvent: any = null
  view: "list" | "calendar" = "calendar"

  // List view
  @Prop({ default: {} }) event!: any

  // Calendar view
  currentYear: number | null = null
  currentMonth: number | null = null

  mounted() {
    this.getCalendarEvent(null)
    this.getPaginatedListSessions()
  }

  get lookup_ref() {
    return this.$route.params.event_lookup_ref
  }

  get calendarEventLoading(): any {
    return this.calendarEvent == null
  }

  getCalendarEvent(payload: Dictionary<any> | null) {
    this.calendarEvent = null

    if (!payload) {
      payload = {
        year: this.$route.query.year,
        month: this.$route.query.month,
        ...this.$defaultQuery(),
      }
    }

    this.$api.getCalendarEvent(this.lookup_ref, payload).then(
      (response: any) => {
        this.calendarEvent = response
      },
      () => {}
    )
  }

  nextOffset = null
  loadedSessions = []
  sessionsLoading = false

  getPaginatedListSessions(nextOffset = 0) {
    if (this.sessionsLoading) {
      return
    }

    this.sessionsLoading = true

    this.$api.getPaginatedSessions(this.lookup_ref, nextOffset).then(
      (response: any) => {
        this.loadedSessions = this.loadedSessions.concat(response.results)
        this.nextOffset = response.meta.paginator.next_position
        this.sessionsLoading = false
      },
      () => {
        this.sessionsLoading = false
      }
    )
  }

  get calendarTitle(): string {
    return this.$gettext("Ver en el calendario")
  }

  get listTitle(): string {
    return this.$gettext("Ver en lista")
  }
}
