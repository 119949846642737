
















import "reflect-metadata"
import { Component, Prop } from "vue-property-decorator"
import Vue from "vue"

import SessionCard from "@/components/SessionCard.vue"

@Component({
  components: { SessionCard },
})
export default class EventUngrouped extends Vue {
  @Prop() event!: any
}
