


























import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import ZonesDropdown from "@/components/ZonesDropdown.vue"

import filters from "@/filters"

@Component({
  filters: filters,
  components: { ZonesDropdown },
})
export default class EventDetail extends Vue {
  @Prop({
    type: Object,
    required: true,
    default: null,
  })
  event!: Dictionary<any>
}
